<template>
  <div>
    <div class="overlay" @click="close"/>
    <div class="modal d-flex-column">
      <div class="header d-flex">
        <div class="title flex-1">{{ title }}</div>
        <button class="close flex-0 button-no-style" @click="close">
          <i class="el-icon-close" aria-label="Close pop-up"/>
        </button>
      </div>
      <div class="scroll-container">
        <slot/>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: ['title', 'header'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
  }

  .modal {
    background-color: $white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    z-index: 5000;
    left: 0;

    // mobile
    @media (max-width: $sm) {
      position: fixed;
      height: 99%;
      width: 100%;
      top: 0;

      .scroll-container {
        overflow-y: scroll;
      }

      .header {
        display:-webkit-box;
        .close {
          padding: 8px;
        }
      }
    }

    // desktop
    @media (min-width: $sm) {
      position: absolute;
      top: 40px;
      width: 400px;

      .header {
        display: none;
      }
    }

    .header {
      background-color: $primary;

      .title {
        padding: 15px;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
      }

      .close {
        padding: 8px;
        cursor: pointer;

        i {
          font-size: 2rem;
          color: $white;
        }
      }
    }

    .scroll-container {
      padding: 15px;
      background-color: white;
      z-index: 9999;
    }
  }
</style>
