<template>
  <div class="d-flex-column">
    <CheckBoxGroupRounded
      :choices="memberDisplayType"
      name="Participant Type"
      v-model="inputVal.participantTypes"
    />
    <CheckBoxGroupRounded :choices="activity" name="Activity" v-model="inputVal.activities" />
    <CheckBoxGroupRounded :choices="gender" name="Gender" v-model="inputVal.genders" />
    <CheckBoxGroupRounded :choices="ageLevels" name="Age" v-model="inputVal.ages" />
    <CheckBoxGroupRounded
      :choices="competitionType"
      name="Competition Type"
      v-model="inputVal.competitionTypes"
    />

    <div class="filter-buttons">
    <el-row :gutter="8" type="flex" justify="space-around">
      <el-col :span=14>
        <el-button @click="submit" type="primary" class="full-width">Apply Filters</el-button>
      </el-col>
      <el-col :span=10>
        <el-button @click="clear" type="secondary" class="full-width">Clear All</el-button>
      </el-col>
    </el-row>
  </div>
  </div>
</template>

<script>
import CheckBoxGroupRounded from "@/components/elements/CheckBoxGroupRounded.vue";
import { activity, competitionType, gender, memberDisplayType, ageLevels } from "@/utils/constants";

export default {
  name: "FilterPage",
  components: { CheckBoxGroupRounded },
  props: ["value"],
  methods: {
    submit() {
      this.$emit("submit", this.inputVal);
    },
    clear() {
      this.$emit("clear");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  data() {
    return {
      memberDisplayType,
      activity,
      gender,
      ageLevels,
      competitionType,
      inputVal: this.value,
    };
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  border-radius: 8px !important;
  text-transform: capitalize;
}
.filter-buttons {
  margin: 10px;
}
</style>
